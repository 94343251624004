import { Tooltip } from "react-tooltip";
import { FaQuestionCircle } from "react-icons/fa";
import { CSSProperties } from "styled-components";

export default function TooltipComponent({
  Icon,
  children,
  style
}: {
  style: CSSProperties;
  className?: string;
  Icon?: any;
  children?: any;
}) {
  const id = Math.random().toString(36).substring(2, 8);

  return (
    <div>
      <div data-tooltip-id={id} data-tooltip-place="right" style={style || { margin: "0px 5px" }}>
        {Icon ? (
          <Icon />
        ) : (
          <>
            <FaQuestionCircle />
          </>
        )}
      </div>
      <Tooltip id={id} place="right" className="tooltip">
        {children}
      </Tooltip>
    </div>
  );
}
