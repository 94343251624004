import styled from "styled-components";
import { TypographyLevel, TypographyVariant } from "./Typography";
import { getThemeProps } from "../../utils/styles";
import { LayoutType } from "../../types/styles.types";
import { colorsAsRgbString } from "@common-ground-io/colors";

type Props = {
  level: TypographyLevel;
  variant: TypographyVariant;
};

type ThemeProps = {
  fontColor: string;
  highlightColor: string;
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
};

type ThemeVariants = {
  copy: ThemeProps;
  pageTitle: ThemeProps;
  secondaryTitle: ThemeProps;
  subTitle: ThemeProps;
};

const fontColorMap: { [key: string]: string } = {
  info: colorsAsRgbString.info,
  warning: colorsAsRgbString.alert,
  success: colorsAsRgbString.success,
  secondary: colorsAsRgbString.grey
};

const themeValues = {
  copy: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "normal"
  },
  subTitle: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "22px"
  },
  secondaryTitle: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "24px"
  },
  pageTitle: {
    fontSize: "29px",
    fontWeight: 600,
    lineHeight: "normal"
  }
};

const fontColorLight = colorsAsRgbString.greyLight;
const fontColorDark = colorsAsRgbString.greyDark;
const highlightLight = colorsAsRgbString.greyLightest;
const highlightDark = colorsAsRgbString.greyDarkest;

const layout: LayoutType<ThemeProps, ThemeVariants> = {
  light: {
    copy: {
      fontColor: fontColorDark,
      highlightColor: highlightDark,
      ...themeValues.copy
    },
    subTitle: {
      fontColor: fontColorDark,
      highlightColor: highlightDark,
      ...themeValues.subTitle
    },
    secondaryTitle: {
      fontColor: fontColorDark,
      highlightColor: highlightDark,
      ...themeValues.secondaryTitle
    },
    pageTitle: {
      fontColor: fontColorDark,
      highlightColor: highlightDark,
      ...themeValues.pageTitle
    }
  },
  dark: {
    copy: {
      fontColor: fontColorLight,
      highlightColor: highlightLight,
      ...themeValues.copy
    },
    subTitle: {
      fontColor: fontColorLight,
      highlightColor: highlightLight,
      ...themeValues.subTitle
    },
    secondaryTitle: {
      fontColor: fontColorLight,
      highlightColor: highlightLight,
      ...themeValues.secondaryTitle
    },
    pageTitle: {
      fontColor: fontColorLight,
      highlightColor: highlightLight,
      ...themeValues.pageTitle
    }
  }
};

export const StyledTypography = styled.div<Props>`
  font-family: inherit;
  margin: 0;
  line-height: ${props => getThemeProps(layout, props).lineHeight};
  font-size: ${props => getThemeProps(layout, props).fontSize};
  font-weight: ${props => (props.level === "highlight" ? 600 : getThemeProps(layout, props).fontWeight)};
  color: ${({ level, ...props }) => {
    let color = fontColorMap?.[level];
    if (!color) {
      color = level === "highlight" ? getThemeProps(layout, props).highlightColor : getThemeProps(layout, props).fontColor;
    }
    return color;
  }};
`;

export const StyledLink = styled.div<{ level: TypographyLevel }>`
  &,
  & > * {
    font-family: inherit;
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
`;
