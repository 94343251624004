import React, { useState, useRef, useEffect } from "react";
import { Redirect, Prompt, Link } from "react-router-dom";
import Button from "../styled/button";
import Search from "../styled/search";
import Zone from "../styled/zone";
import Loader from "../common/loader";
import Input from "../styled/input";
import InputWithSubmit from "../styled/inputWithSubmit";
import { Select, SelectCreatable } from "../styled/select";
import Listing from "../listings/single";
import Image from "../image";
import FileUploader from "../fileUploader";
import moment from "moment";
import { FormatEditor, DescriptionEditor, TracklistEditor, IdentifiersEditor, getDefaultListingData } from "./inputs";
import ImageDropzone from "../common/imageDropzone";
import Four0Four from "../global/404";
import { discogsSearch } from "../../@services/discogsService";
import DatePicker from "../styled/datePicker";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_ITEM,
  POST_ITEM_CREATE,
  POST_ITEM_CREATE_SNIPPET,
  POST_ITEM_CREATE_SNIPPET_SIGNED_URL,
  POST_ITEM_IMAGE_REGISTER,
  POST_ITEM_IMAGE_URL,
  POST_ITEM_UPDATE,
  POST_ITEM_UPDATE_FROM_DISCOGS
} from "../../graphql/queries/item";
import isNumber from "is-number";
import { sanitizeListingData, removeTypenameAndIds, removeTypename, removeTypenameAndRef, uploadToS3, swapElement } from "../../utils";
import {
  Config,
  Item,
  Listing as IListing,
  ReleaseArtist,
  ItemDescriptions,
  ReleaseLabel,
  ReleaseFormat,
  ReleaseTrack,
  ReleaseImage,
  ItemDataIdentifier,
  ItemType
} from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { GlobalStore } from "../../stores/global";
import { GET_CONFIG_METADATA_RELEASES } from "../../graphql/queries/config";
import clone from "clone";
import { TFunction } from "i18next";
import { Typography } from "../../componentsV2/Typography";

import { getData, getName, getCode } from "country-list";
import { useTranslation } from "react-i18next";

interface MissingFields {
  title?: boolean;
  labels?: boolean;
  weight?: boolean;
  artists?: boolean;
  country?: boolean;
}

export interface ReleaseTrackEditable extends ReleaseTrack {
  isUploading?: boolean;
  isUploaded?: boolean;
}

interface ReleaseImageEditable extends ReleaseImage {
  alternativeUploading?: boolean;
}

export interface ItemEditable {
  _id?: string;
  id?: number;
  type: string;
  path?: string;
  descriptions: ItemDescriptions;
  data: {
    title: string;
    releaseDate: string;
    artists: ReleaseArtist[];
    labels: ReleaseLabel[];
    formats: ReleaseFormat[];
    weight?: number | null;
    country: string;
    genres: string[];
    styles: string[];
    assetLink: string;
    images: ReleaseImageEditable[];
    tracklist: ReleaseTrackEditable[];
    discogsId?: number | null;
    identifiers: ItemDataIdentifier[];
  };
  listings: IListing[];
}

const convertEditable = (item: Item): ItemEditable => {
  const dataToReturn = {
    _id: item._id,
    id: item.id,
    path: item.path,
    type: "ReleaseItem",
    descriptions: item.descriptions,
    data: {
      title: item.data.title || "",
      artists: item.data.artists || [],
      labels: item.data.labels || [],
      formats: item.data.formats || [],
      styles: item.data.styles || [],
      genres: item.data.genres || [],
      country: item.data.country || "",
      images: item.data.images || [],
      weight: item.data.weight,
      assetLink: item.data.assetLink || "",
      releaseDate: item.data.releaseDate,
      discogsId: item.data.discogsId,
      identifiers: item.data.identifiers || [],
      tracklist: item.data.tracklist || []
    },
    listings: item.listings
  };
  return dataToReturn;
};

const EditRelease = ({
  id,
  isNew,
  t,
  addNotification
}: {
  id: number;
  config: Config;
  isNew: boolean;
  setItem: any;
  addNotification: AddNotification;
  t: TFunction;
}) => {
  const { config, configReload } = GlobalStore.useState(c => c);

  const [getItem] = useLazyQuery(GET_ITEM, { variables: { id }, fetchPolicy: "cache-and-network" });
  const [item, setItem] = useState<ItemEditable | undefined>();

  useEffect(() => {
    if (!isNew)
      getItem().then(({ data }) => {
        if (data?.item) setItem(convertEditable(clone(data?.item)));
        else addNotification({ ok: 0, message: "Item not found" });
      });
    else {
      const initialData = {
        title: "",
        images: [],
        styles: [],
        genres: [],
        labels: [],
        country: "",
        artists: [],
        discogsId: null,
        releaseDate: moment().format(),
        // eslint-disable-next-line quotes
        formats: [{ name: "Vinyl", qty: "1", descriptions: ['12"'] }],
        tracklist: [],
        weight: null,
        assetLink: "",
        year: null,
        identifiers: []
      };

      setItem({
        listings: [getDefaultListingData(config as Config, ItemType.ReleaseItem)],
        descriptions: { main: "", shop: { text: "", html: "" } },
        data: initialData,
        type: "ReleaseItem"
      });
    }
  }, [getItem]);

  if (item)
    return (
      <Content item={item} configReload={configReload} addNotification={addNotification} config={config as Config} isNew={!item.id} t={t} />
    );
  else if (item === null) return <Four0Four />;
  else return <Loader />;
};

const Content = ({
  item: itemProp,
  config,
  configReload,
  isNew,
  t,
  addNotification
}: {
  item: ItemEditable;
  config: Config;
  configReload: any;
  isNew: boolean;
  t: TFunction<"ns1", undefined>;
  addNotification: AddNotification;
}) => {
  const formId = "itemForm";
  const [redirect, setRedirect] = useState<string>();
  const [formData] = useState(new FormData());
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingFromDiscogs, setIsFetchingFromDiscogs] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const newListingRef = useRef<any>();
  const [missingFields, setMissingFields] = useState<MissingFields>({});
  const [createItem] = useMutation(POST_ITEM_CREATE);
  const [updateItem] = useMutation(POST_ITEM_UPDATE);
  const [getSignedUrl] = useMutation(POST_ITEM_CREATE_SNIPPET_SIGNED_URL);
  const [getImageSignedUrl] = useMutation(POST_ITEM_IMAGE_URL);
  const [registerImage] = useMutation(POST_ITEM_IMAGE_REGISTER);
  const [createSnippetEntry] = useMutation(POST_ITEM_CREATE_SNIPPET);
  const [updateReleaseFromDiscogs] = useMutation(POST_ITEM_UPDATE_FROM_DISCOGS);
  const { data: metadata } = useQuery(GET_CONFIG_METADATA_RELEASES, { fetchPolicy: "no-cache" });
  const [item, setItem] = useState<ItemEditable>(itemProp);

  const handleGenericInputUpdate = (name: string, value: string) => {
    setIsDirty(true);
    // @ts-ignore
    item.data[name] = value;
    setItem({ ...item });
    // @ts-ignore
    if (missingFields[name] && value) setMissingFields({ ...missingFields, [name]: false });
  };

  const handleAddArtist = (artistObj: any) => {
    setIsDirty(true);
    if (!artistObj.title) return;
    item.data.artists.push({ name: artistObj.title, id: artistObj.id || new Date().getTime() });
    setItem({ ...item });
    if (missingFields.artists && item.data.artists.length) setMissingFields({ ...missingFields, artists: false });
  };

  const handleAddLabel = (labelObj: { title: string; id: number }) => {
    setIsDirty(true);
    if (!labelObj.title) return;
    item.data.labels.push({ name: labelObj.title, id: labelObj.id || new Date().getTime() });
    setItem({ ...item });
    if (missingFields.labels && item.data.labels.length) setMissingFields({ ...missingFields, labels: false });
  };

  const handleRemoveArtist = (obj: { name: string }) => {
    setIsDirty(true);
    const index = item.data.artists.findIndex(a => a.name === obj.name);
    item.data.artists.splice(index, 1);
    setItem({ ...item });
  };

  const handleRemoveLabel = (obj: { name: string }) => {
    setIsDirty(true);
    const index = item.data.labels.findIndex(a => a.name === obj.name);
    item.data.labels.splice(index, 1);
    setItem({ ...item });
  };

  const handleEditLabel = (label: ReleaseLabel, value: string) => {
    setIsDirty(true);
    const index = item.data.labels.findIndex(a => a.name === label.name);
    item.data.labels[index].catno = value;
    setItem({ ...item });
  };

  const handleAvailableChange = (e: any) => {
    setIsDirty(true);
    if (!e || !moment().isValid()) return;
    item.data.releaseDate = moment(e).format();
    setItem({ ...item });
  };

  const handleCountryChange = (country: string) => {
    setIsDirty(true);
    item.data.country = getName(country) || "";
    setItem({ ...item });
    if (missingFields.country && item.data.country.length) setMissingFields({ ...missingFields, country: false });
  };

  const handleAddDescription = (index: number) => {
    setIsDirty(true);
    const format = item.data.formats[index];
    if (format) format.descriptions.push("");
    setItem({ ...item });
  };

  const handleDescriptionUpdate = (object: any) => {
    setIsDirty(true);
    item.data.formats[object.formatIndex].descriptions[object.index] = object.value;
    setItem({ ...item });
  };

  const handleDescriptionDelete = ({ formatIndex, index }: { formatIndex: number; index: number }) => {
    setIsDirty(true);
    item.data.formats[formatIndex].descriptions.splice(index, 1);
    setItem({ ...item });
  };

  const handleFormatDelete = (index: number) => {
    setIsDirty(true);
    item.data.formats.splice(index);
    setItem({ ...item });
  };

  const handleFormatAdd = () => {
    setIsDirty(true);
    item.data.formats.push({ qty: "1", name: "Vinyl", descriptions: [] });
    setItem({ ...item });
  };

  const handleFormatHeadUpdate = (object: any) => {
    setIsDirty(true);
    const format = item.data.formats[object.formatIndex];
    // @ts-ignore
    if (format) format[object.name] = object.value;
    setItem({ ...item });
  };

  const handleShopDescriptionUpdate = (content: string) => {
    setIsDirty(true);
    item.descriptions.shop.text = content;
    setItem({ ...item });
  };

  const handleShortDescriptionUpdate = (content: string) => {
    setIsDirty(true);
    if (content.length > 160) return addNotification({ ok: 0, message: "Max number of characters is 160" });
    item.descriptions.shop.short = content;
    setItem({ ...item });
  };

  const handleTracklistInputChange = (object: any) => {
    setIsDirty(true);
    // @ts-ignore
    item.data.tracklist[object.index][object.name] = object.value;
    setItem({ ...item });
  };

  const handleIdentifierUpdate = (object: any) => {
    setIsDirty(true);
    // @ts-ignore
    item.data.identifiers = object;
    setItem({ ...item });
  };

  const handleSnippetError = (message: string) => {
    addNotification({ ok: 0, message });
  };

  const handleSnippetDrop = async (position: string, file: File) => {
    if (isNew) {
      return addNotification({
        ok: 0,
        message: "Save item document before uploading snippets..."
      });
    }
    const track = item.data.tracklist.find(t => t.position === position);
    if (!track || !position) {
      return addNotification({
        ok: 0,
        message: "Please name the track position before uploading snippets "
      });
    }
    track.isUploading = true;
    setItem({ ...item });
    try {
      const { data } = await getSignedUrl({
        variables: { releaseId: item.id, filename: file.name }
      });
      if (!data) throw new Error("Invalid URL");
      const { key, signedUrl } = data.itemSnippetSignedUrlGenerate;
      const options = {
        headers: {
          "Content-Type": file.type,
          "Cache-Control": "max-age=31536000",
          "x-amz-acl": "public-read"
        }
      };
      const putResults = await uploadToS3(signedUrl, file, options);
      if (putResults.status === 200) {
        const snippetData = {
          key,
          filename: file.name,
          releaseId: item.id,
          position
        };
        const { data } = await createSnippetEntry({ variables: snippetData });
        track.uri = data?.itemSnippetCreate.uri;
        track.isUploading = false;
        track.isUploaded = true;
      }
    } catch (e: any) {
      console.error(e);
      addNotification({ ok: 0, message: e.toString() });
    } finally {
      track.isUploading = false;
      setItem({ ...item });
    }
  };

  const sanitizeReleaseData = (item: ItemEditable) => {
    return {
      discogsId: isNumber(item.data.discogsId) ? Number(item.data.discogsId) : null,
      title: item.data.title,
      identifiers: removeTypenameAndIds(item.data.identifiers),
      country: item.data.country,
      formats:
        item.data.formats.map(f => ({ qty: f.qty, name: f.name, text: f.text, descriptions: f.descriptions.filter(e => !!e) })) || [],
      tracklist:
        item.data.tracklist.map(t => ({
          artists: removeTypenameAndRef(t.artists),
          type_: t.type_,
          title: t.title,
          position: t.position,
          duration: t.duration
        })) || [],
      releaseDate: item.data.releaseDate,
      weight: typeof item.data.weight === "string" ? parseInt(item.data.weight) : item.data.weight,
      genres: item.data.genres || [],
      styles: item.data.styles || [],
      images: removeTypenameAndIds(item.data.images) || [],
      artists: removeTypename(item.data.artists) || [],
      assetLink: item.data.assetLink || null,
      labels: removeTypename(item.data.labels) || []
    };
  };

  const handleSubmitItemData = async (e: any, noRedirect?: boolean) => {
    if (e) e.preventDefault();
    try {
      setIsSubmitting(true);
      isSubmittable();
      if (item._id)
        await updateItem({
          variables: {
            itemRef: item._id,
            descriptions: {
              short: item.descriptions.shop.short,
              long: item.descriptions.shop.text
            },
            itemUpdateReleaseInput: sanitizeReleaseData(item)
          }
        });
      setIsDirty(false);
      if (!noRedirect) {
        addNotification({ message: "Item updated", ok: 1 });
        setRedirect(item.path);
      }
    } catch (e: any) {
      addNotification({ message: e.message, ok: 0 });
    } finally {
      setIsSubmitting(false);
    }
  };

  const readUploadedFileAsText = (inputFile: File) => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  const handleAlternativeImageUpload = async (file: File, index: number) => {
    if (!file) return;
    item.data.images[index].alternativeUploading = true;
    setItem({ ...item });

    const fileToSend = file;
    const { data } = await getImageSignedUrl({ variables: { itemRef: item._id as string, filename: fileToSend.name } });
    if (!data?.itemImageSignedUrl) return;
    const options = { headers: { "Content-Type": fileToSend.type, "Cache-Control": "max-age=31536000" } };
    await uploadToS3(data.itemImageSignedUrl.signedUrl, fileToSend, options);
    const registerData = {
      key: data.itemImageSignedUrl.key,
      filename: fileToSend.name,
      index,
      itemRef: item._id as string,
      alternative: true
    };
    const { data: imageRegisterResults } = await registerImage({ variables: registerData });
    if (imageRegisterResults?.itemImageRegister) {
      setItem({ ...(imageRegisterResults.itemImageRegister as ItemEditable) });
      addNotification({ ok: 1, message: t("Image added") });
    }
  };

  const handleDeleteAlternativeImage = async (index: number) => {
    setIsDirty(true);
    delete item.data.images[index].alternative;
    setItem({ ...item });
  };

  const handleImageDrop = async (formData: FormData, files: File[]) => {
    setIsDirty(true);
    if (isNew) {
      await files.forEach(async f => {
        try {
          const fileContents = await readUploadedFileAsText(f);
          item.data.images.push({ uri: fileContents as string });
          setItem({ ...item });
        } catch (e: any) {
          console.error(e);
          addNotification({ ok: 0, message: e.toString() });
        }
      });
    } else if (item._id) {
      await handleSubmitItemData(null, true);
      setIsUploadingImages(true);
      formData.set("itemRef", item._id);
      try {
        for (const fileToSend of formData.getAll("files")) {
          const { data } = await getImageSignedUrl({ variables: { itemRef: item._id, filename: (fileToSend as File).name } });
          if (!data?.itemImageSignedUrl) continue;
          const options = { headers: { "Content-Type": (fileToSend as File).type, "Cache-Control": "max-age=31536000" } };
          await uploadToS3(data.itemImageSignedUrl.signedUrl, fileToSend as File, options);
          const registerData = {
            key: data.itemImageSignedUrl.key,
            filename: (fileToSend as File).name,
            itemRef: item._id,
            alternative: false
          };
          const { data: registeredData } = await registerImage({ variables: registerData });
          if (registeredData?.itemImageRegister) {
            setItem({ ...(registeredData?.itemImageRegister as ItemEditable) });
            addNotification({ ok: 1, message: t("Image added") });
          }
        }
        formData.delete("files");
      } catch (e: any) {
        console.error(e);
        addNotification({ ok: 0, message: e.toString() });
      } finally {
        setIsUploadingImages(false);
      }
    }
  };

  const handleAddItem = async (e: any) => {
    e.preventDefault();
    try {
      isSubmittable();
      if (!newListingRef.current) return addNotification({ ok: 0, message: "Listing error" });
      const listingToAdd = newListingRef.current.getListing();

      setIsSubmitting(true);
      const itemDataToSend = sanitizeReleaseData(item);
      itemDataToSend.images = [];

      sanitizeListingData(listingToAdd);

      if ((!isNumber(listingToAdd.prices.sale) && !isNumber(listingToAdd.prices.beforeTaxes)) || !isNumber(listingToAdd.stock.quantity)) {
        addNotification({ ok: 0, message: "Price and stock values are required" });
        setIsSubmitting(false);
        return;
      }

      const images = formData.getAll("files");

      const descriptions = { short: item.descriptions.shop.short, long: item.descriptions.shop.text };

      const { data } = await createItem({
        variables: { type: "ReleaseItem", descriptions, listings: [listingToAdd], itemCreateReleaseInput: itemDataToSend }
      });
      const addedItem = data?.itemCreate;
      if (!addedItem) throw new Error("Item create error");
      if (images.length) {
        try {
          for (const fileToSend of formData.getAll("files") as any) {
            const { data } = await getImageSignedUrl({ variables: { itemRef: addedItem._id, filename: fileToSend.name } });
            if (!data?.itemImageSignedUrl) continue;
            const options = { headers: { "Content-Type": fileToSend.type, "Cache-Control": "max-age=31536000" } };
            await uploadToS3(data?.itemImageSignedUrl.signedUrl, fileToSend, options);
            const registerData = {
              key: data?.itemImageSignedUrl.key,
              filename: fileToSend.name,
              itemRef: addedItem._id,
              alternative: false
            };
            await registerImage({ variables: registerData });
          }
        } catch (e: any) {
          console.error(e);
          addNotification({ ok: 0, message: e.toString() });
        } finally {
          setIsUploadingImages(false);
        }
      }
      setRedirect(addedItem.path);
      addNotification({ ok: 1, message: "Item created" });
      configReload();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddTrack = async () => {
    setIsDirty(true);
    item.data.tracklist.push({
      type_: "track",
      position: undefined,
      title: undefined
    });
    setItem({ ...item });
  };

  const handleDeleteTrack = async (index: number) => {
    setIsDirty(true);
    item.data.tracklist.splice(index, 1);
    setItem({ ...item });
  };

  const isSubmittable = () => {
    if (isSubmitting) return false;

    if (!item.data.title) throw new Error(t("A title is required"));
    if (!isNumber(item.data.weight)) throw new Error(t("A weight is required"));
    if (!item.data.labels.length) throw new Error(t("A label is required"));
    if (!item.data.artists.length) throw new Error(t("An artist is required"));
    if (!item.data.country) throw new Error(t("A country is required"));
  };

  const handleUpdateFromDiscogs = async () => {
    if (isDirty || !item._id) return addNotification({ ok: 0, message: "Please save changes first" });
    setIsFetchingFromDiscogs(true);
    try {
      await updateReleaseFromDiscogs({ variables: { itemRef: item._id } });
      addNotification({ ok: 1, message: "Item updated to latest" });
      setRedirect(item.path);
    } catch (e: any) {
      console.error(e);
      addNotification({ ok: 0, message: e.toString() });
    }
    setIsFetchingFromDiscogs(false);
  };

  // const handleDiscogsSearch = async (term, type) => {
  //   const { data } = await discogsSearch({
  //     token: getConfigProperty(config, "discogs", "token"),
  //     term,
  //     type,
  //     accessData: getConfigProperty(config, "discogs", "accessData")
  //   });
  //   return data;
  // };

  const handleDeleteImage = async (index: number) => {
    setIsDirty(true);
    item.data.images.splice(index, 1);
    if (!isNew) {
      try {
        setItem({ ...item });
      } catch (e: any) {
        console.error(e);
        addNotification({ message: e.toString(), ok: 0 });
      }
    } else {
      const files = formData.getAll("files");
      files.splice(index, 1);
      formData.delete("files");
      files.forEach(item => {
        formData.append("files", item);
      });
      setItem({ ...item });
    }
  };

  const handleStyleChange = (entries: { value: string }[]) => {
    item.data.styles = entries.map(e => e.value);
    setItem({ ...item });
  };

  const handleGenreChange = (entries: { value: string }[]) => {
    item.data.genres = entries.map(e => e.value);
    setItem({ ...item });
  };

  const handleReorder = async (index: number, direction: string) => {
    setIsDirty(true);
    const copiedImages = item.data.images;
    if (direction === "up" && index > 0) {
      swapElement(copiedImages, index, index - 1);
    } else if (direction === "down" && index < copiedImages.length - 1) {
      swapElement(copiedImages, index, index + 1);
    }
    setItem({ ...item });
  };

  if (redirect) return <Redirect to={redirect} />;
  if (!isNew && item === null) return <Four0Four />;
  if (!isNew && item === undefined) return <Loader />;
  if (!item) return null;

  const shopDescription = item.descriptions.shop.text;
  const hasDiscogsToken = config.discogs.enabled;

  return (
    <div id="itemEdit">
      <Prompt when={isDirty} message={() => t("Your changes will be lost, are you sure you want to leave this page ?")} />
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h1">
            {isNew ? t("Add a release") : t("Edit Release")}
          </Typography>
          {!isNew && item.path ? (
            <Link to={item.path}>
              <Button variant="noStyle" type="button" className="reset">
                {t("Back")}
              </Button>
            </Link>
          ) : null}
        </div>
        <div className="right">
          {item.data.discogsId ? (
            <ButtonV2
              variant="secondary"
              disabled={isFetchingFromDiscogs || isDirty}
              onClick={() => {
                if (window.confirm(t("Are you sure you wish to revert release information to default?"))) handleUpdateFromDiscogs();
              }}>
              {isFetchingFromDiscogs ? t("Fetching") + "..." : t("Fetch data from Discogs")}
            </ButtonV2>
          ) : null}
          <ButtonV2 variant="primary" type="submit" form={formId} disabled={isSubmitting}>
            {isSubmitting ? <Loader /> : isNew ? t("Submit") : t("Save")}
            {isDirty ? "*" : ""}
          </ButtonV2>
        </div>
      </section>
      <div id="content">
        <form onSubmit={isNew ? handleAddItem : handleSubmitItemData} id={formId}>
          <section id="images">
            <h3>{t("Images")}</h3>
            <ImageDropzone formData={formData} uploadingState={isUploadingImages} onDrop={handleImageDrop} t={t} />
            {item.data.images.length ? (
              <div className="entries">
                {item.data.images.map((i, index) => (
                  <div key={`${i.uri}-${index}`} className="image">
                    <ImageEntry index={index} item={item} image={i} handleMove={direction => handleReorder(index, direction)} />
                    <div className="imageOptions">
                      <Button variant="danger" type="button" onClick={() => handleDeleteImage(index)}>
                        {t("Delete")}
                      </Button>
                      {i.alternative ? (
                        <div className="alternative">
                          <Button type="button" variant="danger" onClick={() => handleDeleteAlternativeImage(index)}>
                            {t("Delete hover image")}
                          </Button>
                        </div>
                      ) : (
                        <FileUploader
                          isUploading={!!i.alternativeUploading}
                          accept="image/png, image/jpeg"
                          label={t("Add hover image")}
                          name="alternative"
                          handleFile={(file: File) => handleAlternativeImageUpload(file, index)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </section>
          <section id="information">
            <h3>{t("Item information")}</h3>
            <div id="fields">
              <Input
                label={t("Title") + "*"}
                name="title"
                required
                type="text"
                className={missingFields.title ? "required" : ""}
                defaultValue={item.data.title}
                placeholder={t("Title") + "..."}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <Select
                label={t("Country") + "*"}
                value={{ value: getCode(item.data.country || ""), label: item.data.country || "" }}
                onChange={(option: any) => handleCountryChange(option.value)}
                options={getData().map((c: any) => ({ label: c.name, value: c.code }))}
                className={missingFields.country ? "type required" : "type"}
                placeholder={t("Select") + "..."}
              />
              <div className="rowFields">
                <Input
                  label={t("Weight in grams") + "*"}
                  name="weight"
                  type="number"
                  required
                  step="1"
                  min="0"
                  onWheel={(e: any) => e.target.blur()}
                  value={item.data.weight || ""}
                  placeholder={t("Weight in grams") + "..."}
                  className={missingFields.weight ? "required" : ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleGenericInputUpdate(e.target.name, e.target.value)}
                />
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={item.data.releaseDate ? moment(item.data.releaseDate).format() : ""}
                  onChange={handleAvailableChange}
                  label={t("Release date")}
                  className="releaseDate"
                  closeOnSelect={true}
                />
              </div>
              <label>
                <Input
                  label={t("Discogs ID")}
                  type="number"
                  name="discogsId"
                  placeholder={t("Discogs ID") + "..."}
                  value={item.data.discogsId || ""}
                  onWheel={(e: any) => e.target.blur()}
                  onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
                />
              </label>
              <SelectCreatable
                isMulti={true}
                label={t("Styles")}
                value={item.data.styles.map(s => ({ label: s, value: s }))}
                onChange={(option: any) => handleStyleChange(option)}
                options={(metadata?.configMetadata?.styles || []).map((c: string) => ({ label: c, value: c }))}
              />
              <SelectCreatable
                isMulti={true}
                label={t("Genres")}
                value={item.data.genres.map(s => ({ label: s, value: s }))}
                onChange={(option: any) => handleGenreChange(option)}
                options={(metadata?.configMetadata?.genres || []).map(c => ({ label: c, value: c }))}
              />
              <ArtistEntries
                entries={item.data.artists}
                config={config}
                onSelect={handleAddArtist}
                onDelete={handleRemoveArtist}
                label={t("Artists") + "*"}
                hasDiscogsToken={hasDiscogsToken}
                t={t}
              />
              <LabelEntries
                entries={item.data.labels || []}
                config={config}
                label={t("Labels") + "*"}
                onSelect={handleAddLabel}
                onEdit={handleEditLabel}
                onDelete={handleRemoveLabel}
                hasDiscogsToken={hasDiscogsToken}
                t={t}
              />
              <FormatEditor
                handleAddDescription={handleAddDescription}
                formats={item.data.formats || []}
                handleDescriptionUpdate={handleDescriptionUpdate}
                handleDeleteDescription={handleDescriptionDelete}
                handleDeleteFormat={handleFormatDelete}
                handleFormatAdd={handleFormatAdd}
                handleFormatHeadUpdate={handleFormatHeadUpdate}
                label={t("Format")}
                t={t}
              />
              <div>
                <DescriptionEditor
                  label={t("Tag line / SEO description - 160 characters max")}
                  content={item.descriptions.shop.short || ""}
                  onChange={handleShortDescriptionUpdate}
                />
                <DescriptionEditor label={t("Full description")} content={shopDescription || ""} onChange={handleShopDescriptionUpdate} />
              </div>
              <IdentifiersEditor item={item} handleIdentifierUpdate={handleIdentifierUpdate} t={t} />
              <Input
                label={t("Public asset download link")}
                type="url"
                name="assetLink"
                value={item.data.assetLink || ""}
                placeholder={t("An URL link to download extra assets") + "..."}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <TracklistEditor
                tracklist={item.data.tracklist || []}
                label={t("Tracklist")}
                release={item}
                new={isNew}
                config={config}
                onInputChange={handleTracklistInputChange}
                onSnippetDrop={handleSnippetDrop}
                onError={handleSnippetError}
                onAddTrack={handleAddTrack}
                onDeleteTrack={handleDeleteTrack}
                t={t}
              />
            </div>
          </section>
        </form>
      </div>
      {isNew ? (
        <Zone id="listings">
          <div id="listingsHeader" />
          <Listing
            config={config}
            overZone={true}
            listing={item.listings[0]}
            ref={newListingRef}
            item={item}
            edit={isNew || false}
            hideSubmit={true}
          />
        </Zone>
      ) : null}
    </div>
  );
};

export const ImageEntry = ({
  index,
  image,
  item,
  handleMove
}: {
  index: number;
  item: ItemEditable | Item;
  image: ReleaseImage;
  handleMove: (direction: string) => void;
}) => {
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation();
  return (
    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} style={{ position: "relative" }}>
      <div
        style={{
          zIndex: 2,
          position: "absolute",
          visibility: isHover ? "initial" : "hidden",
          top: "15px",
          left: "15px"
        }}>
        <div style={{ display: "flex", gap: "15px" }}>
          {index > 0 ? (
            <ButtonV2 onClick={() => handleMove("up")} variant="primary">
              {t("Move up")}
            </ButtonV2>
          ) : null}
          {index < item.data.images.length - 1 ? (
            <ButtonV2 onClick={() => handleMove("down")} variant="primary">
              {t("Move down")}
            </ButtonV2>
          ) : null}
        </div>
      </div>
      <Image className="primary" entry={image} alt={item.data.title} />
    </div>
  );
};

export const ArtistEntries = ({
  config,
  entries,
  onSelect,
  onDelete,
  label,
  hasDiscogsToken,
  t
}: {
  config: Config;
  entries: ReleaseArtist[];
  onSelect: any;
  onDelete: any;
  label: string;
  hasDiscogsToken: boolean;
  t: TFunction;
}) => {
  const [withDiscogs, setWithDiscogs] = useState(hasDiscogsToken);
  const [artistInput, setArtistInput] = useState("");

  const token = config.discogs.token;
  const accessData = config.discogs.accessData;

  const handleSearch = async (term: string) => {
    const { data } = await discogsSearch({
      token,
      term,
      type: "artist",
      accessData
    });
    return data.results;
  };

  const handleSelect = (e: any, entry: any) => {
    e.preventDefault();
    onSelect(entry);
  };

  const suggestionComponent = ({ entry, handleResultClick }: { entry: any; handleResultClick: any }) => {
    return (
      <div className="suggestion">
        <img src={entry.thumb} />
        <Link
          to={"#"}
          onClick={(e: any) => {
            handleSelect(e, entry);
            handleResultClick();
          }}>
          {entry.title} - {entry.id}
        </Link>
      </div>
    );
  };

  return (
    <Zone className="artistEntries">
      {hasDiscogsToken ? (
        <div className="header">
          <ButtonV2 type="button" onClick={() => setWithDiscogs(!withDiscogs)} variant="secondary">
            {t("Add {{isWith}} Discogs", { isWith: !withDiscogs ? t("with") : t("without") })}
          </ButtonV2>
        </div>
      ) : null}
      {withDiscogs ? (
        <Search
          variant="overZone"
          label={label}
          className="artistsSearch"
          debounceTime={500}
          placeholder={t("Search artists through the Discogs database") + "..."}
          search={handleSearch}
          suggestionComponent={suggestionComponent}
        />
      ) : null}
      {!withDiscogs ? (
        <InputWithSubmit
          form="artist-form"
          fullWidth
          label={label}
          onChange={(e: any) => setArtistInput(e.target.value)}
          placeholder={t("Artist name") + "..."}
          name="artist"
          variant="overZone"
          type="text"
          submitText="Add"
          onClick={(e: any) => {
            e.preventDefault();
            setArtistInput("");
            onSelect({ title: artistInput });
          }}
        />
      ) : null}
      <div className="entries">
        {entries.map((a, i) => (
          <div className="entry" key={i}>
            <span>
              {a.name} {a.anv || ""}
            </span>
            <Button variant="noStyle" type="button" onClick={() => onDelete({ name: a.name })}>
              X
            </Button>
          </div>
        ))}
      </div>
    </Zone>
  );
};

export const LabelEntries = ({
  config,
  entries,
  onSelect,
  onDelete,
  label,
  hasDiscogsToken,
  onEdit,
  t
}: {
  config: Config;
  entries: ReleaseLabel[];
  onSelect: any;
  onDelete: any;
  label: string;
  hasDiscogsToken: boolean;
  onEdit: any;
  t: TFunction;
}) => {
  const [withDiscogs, setWithDiscogs] = useState(hasDiscogsToken);
  const [artistInput, setArtistInput] = useState("");
  const token = config.discogs.token;
  const accessData = config.discogs.accessData;

  const handleSearch = async (term: string) => {
    const { data } = await discogsSearch({
      token,
      term,
      type: "label",
      accessData
    });
    return data.results;
  };

  const handleSelect = (e: any, entry: any) => {
    e.preventDefault();
    onSelect(entry);
  };

  const suggestionComponent = ({ entry, handleResultClick }: { entry: any; handleResultClick: any }) => {
    return (
      <div className="suggestion">
        <img src={entry.thumb} />
        <Link
          to={"#"}
          onClick={(e: any) => {
            handleSelect(e, entry);
            handleResultClick();
          }}>
          {entry.title} - {entry.id}
        </Link>
      </div>
    );
  };

  return (
    <Zone className="labelEntries">
      {hasDiscogsToken ? (
        <div className="header">
          <ButtonV2 type="button" onClick={() => setWithDiscogs(!withDiscogs)} variant="secondary">
            {t("Add {{isWith}} Discogs", { isWith: !withDiscogs ? t("with") : t("without") })}
          </ButtonV2>
        </div>
      ) : null}
      {withDiscogs ? (
        <Search
          variant="overZone"
          label={label}
          className="artistsSearch"
          debounceTime={500}
          placeholder={t("Search {{word}} through the Discogs database", { word: t("Artists") }) + "..."}
          search={handleSearch}
          suggestionComponent={suggestionComponent}
        />
      ) : null}
      {!withDiscogs ? (
        <InputWithSubmit
          form="artist-form"
          fullWidth
          label={label}
          onChange={(e: any) => setArtistInput(e.target.value)}
          placeholder={t("Label") + "..."}
          name="label"
          variant="overZone"
          type="text"
          submitText="Add"
          onClick={(e: any) => {
            e.preventDefault();
            setArtistInput("");
            onSelect({ title: artistInput });
          }}
        />
      ) : null}
      <div className="entries">
        {entries.map((a, i) => (
          <div className="entry" key={i}>
            <span>{a.name}</span>
            <Input variant="overZone" onChange={(e: any) => onEdit(a, e.target.value)} value={a.catno || ""} placeholder={t("Catno")} />
            <Button variant="noStyle" type="button" onClick={() => onDelete({ name: a.name })}>
              X
            </Button>
          </div>
        ))}
      </div>
    </Zone>
  );
};

export default EditRelease;
